import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import { fetchSheetsForCollection } from './allSheetsSlice'

export const fetchAllCollections = createAsyncThunk('collections/fetchAll', async () => {
  const response = await axios.get('/api/user/collections')
  return response.data
})

export const fetchCollection = createAsyncThunk(
  'collections/fetch',
  async ({ collection_id, publicAccess = false }) => {
    const route = publicAccess ? '/api/public/collections' : '/api/user/collections'
    const response = await axios.get(`${route}/${collection_id}`)
    return response.data
  },
)

export const fetchPublicCollection = createAsyncThunk(
  'collections/fetchPublic',
  async ({ collection_id }) => {
    const response = await axios.get(`/api/public/collections/${collection_id}`)
    return response.data
  },
)

export const saveCollection = createAsyncThunk(
  'collections/save',
  async (collection, thunkAPI) => {
    try {
      const response = await axios.post('/api/user/collections/save', collection)
      return response.data
    } catch (e) {
      const error = _.get(e, 'response.data.error')
      return thunkAPI.rejectWithValue(error)
    }
  },
)

// Create a debounced version of the saveCollection action
const debouncedSaveCollection = _.debounce((dispatch, collection) => {
  dispatch(saveCollection(collection))
}, 2000)

export const addSheetToCollectionAndSave = createAsyncThunk(
  'collections/addAndSave',
  async ({ collection_id, sheet_id }, thunkAPI) => {
    try {
      // Dispatch the addSheetToCollection action
      thunkAPI.dispatch(addSheetToCollection({ collection_id, sheet_id }))

      // Get the updated state
      const state = thunkAPI.getState()
      const collection = state.collections.collections[collection_id]

      // Invoke the debounced saveCollection action
      debouncedSaveCollection(thunkAPI.dispatch, collection)
    } catch (e) {
      const error = _.get(e, 'response.data.error')
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const removeSheetFromCollectionAndSave = createAsyncThunk(
  'collections/removeAndSave',
  async ({ collection_id, sheet_id }, thunkAPI) => {
    try {
      // Dispatch the addSheetToCollection action
      thunkAPI.dispatch(removeSheetFromCollection({ collection_id, sheet_id }))

      // Get the updated state
      const state = thunkAPI.getState()
      const collection = state.collections.collections[collection_id]

      // Invoke the debounced saveCollection action
      debouncedSaveCollection(thunkAPI.dispatch, collection)
    } catch (e) {
      const error = _.get(e, 'response.data.error')
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const saveCollectionTitle = createAsyncThunk(
  'collections/saveTitle',
  async (collectionData, thunkAPI) => {
    try {
      const response = await axios.post('/api/user/collections/alter-title', collectionData)
      return response.data
    } catch (e) {
      const error = _.get(e, 'response.data.error')
      return thunkAPI.rejectWithValue(error)
    }
  },
)

export const collectionsSlice = createSlice({
  name: 'collections',
  initialState: {
    loading: false,
    total_collections: null,

    collections: {
      'new-collection': {
        id: null,
        title: '',
        salesheet_ids: [],
      },
    },

    sheets: [],
    influencersBasicInfo: {},
    sheetBasicInfo: {},
    saveState: 'clean',
  },
  reducers: {
    updateCollectionSheet: (state, { payload }) => {
      state.collections[payload.collection_id].sheets[payload.sheet_id] = {
        ...(state.collections[payload.collection_id].sheets[payload.sheet_id] || {}),
        ...payload.updates,
      }
    },
    updateCollectionSheetDescription: (state, { payload }) => {
      state.sheets = state.sheets.map((sheet) =>
        sheet.id === payload.sheet_id ? { ...sheet, description: payload.description } : sheet,
      )
    },
    changeCoverPhoto: (state, { payload }) => {
      state.collections[payload.collection_id].sheets[payload.sheet_id].cover_photo_url =
        payload.cover_photo_url
    },
    setTitle: (state, { payload }) => {
      state.collections[payload.collection_id].title = payload.title
    },
    addSheetToCollection: (state, { payload }) => {
      const existing_sheets = state.collections[payload.collection_id].salesheet_ids
      state.collections[payload.collection_id].salesheet_ids = [
        ...existing_sheets,
        payload.sheet_id,
      ]
      state.collections[payload.collection_id].saveState = 'dirty'
      state.saveState = 'dirty'
    },
    removeSheetFromCollection: (state, { payload }) => {
      const existing_sheets = [...state.collections[payload.collection_id].salesheet_ids]
      state.collections[payload.collection_id].salesheet_ids = existing_sheets.filter(
        (sheet_id) => sheet_id !== payload.sheet_id,
      )
      state.collections[payload.collection_id].saveState = 'dirty'
      state.saveState = 'dirty'
    },
    resetCollection: (state, { payload }) => {
      state.collections[payload.collection_id] = {
        id: null,
        title: '',
        salesheet_ids: [],
      }
    },
    setSalesSheets: (state, { payload }) => {
      state.collections[payload.collection_id].salesheet_ids = payload.salesheet_ids
    },
    removeCollection: (state, { payload }) => {
      delete state.collections[payload.collection_id]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCollections.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAllCollections.fulfilled, (state, { payload }) => {
        state.loading = false
        state.collections = {
          ...state.collections,
          ...payload.collections,
        }
        state.sheets = payload.sheets
      })
      .addCase(fetchCollection.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchCollection.fulfilled, (state, { payload }) => {
        state.loading = false
        state.collections = {
          ...state.collections,
          ...payload.collections,
        }
        state.influencersBasicInfo = payload.influencers.reduce((acc, inf) => {
          acc[inf.id] = inf
          return acc
        }, {})

        state.sheetBasicInfo = payload.sheets.reduce((acc, sheet) => {
          acc[sheet.id] = sheet
          return acc
        }, {})
      })
      .addCase(saveCollectionTitle.fulfilled, (state, { payload }) => {
        console.log(payload)
        state.saveState = 'clean'
        state.collections[payload.collection_id].saveState = 'clean'
        state.collections[payload.collection_id].title = payload.title
      })
      .addCase(fetchSheetsForCollection.fulfilled, (state, { payload }) => {
        state.sheets = payload.sheets
      })
      .addCase(saveCollection.fulfilled, (state, { payload }) => {
        state.collections[payload.collection_id].saveState = 'clean'
        state.saveState = 'clean'
      })
  },
})

// Action creators are generated for each case reducer function
export const {
  setTitle,
  changeCoverPhoto,
  updateCollectionSheet,
  addSheetToCollection,
  removeSheetFromCollection,
  resetCollection,
  setSalesSheets,
  removeCollection,
  updateCollectionSheetDescription,
} = collectionsSlice.actions

export default collectionsSlice.reducer
