import React, { useState } from 'react'
import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  useGeneratedHtmlId,
} from '@elastic/eui'
import axios from 'axios'

export default () => {
  const [isModalVisible, setIsModalVisible] = useState(true)
  const closeModal = () => {
    axios.post('/api/user/mark-notification-read')
    setIsModalVisible(false)
  }
  const modalTitleId = useGeneratedHtmlId()
  return (
    <>
      {isModalVisible && (
        <EuiModal aria-labelledby={modalTitleId} onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle id={modalTitleId}>New Look</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <div
              style={{
                fontSize: '16px',
              }}
            >
              <p>Welcome to the redesigned app! We hope you like the new look and feel.</p>
              <EuiSpacer />
              <p>
                The changes are visible privately in your experience creating Talent Sheets and
                there is also a new look for the "Public Link" page available from your roster.
                Talent Sheets and Profile links will look the same as before.
              </p>
              <EuiSpacer />
              <p>
                If you prefer to continue using the old design for now, you can find a link on the
                Settings page.
              </p>
            </div>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton onClick={closeModal} fill>
              Got it!
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      )}
    </>
  )
}
