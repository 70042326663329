import './SwitchSelector.scss'

function SwitchSelector({ options, selectedOption, onSelect }) {
  return (
    <div className="switch-selector-container">
      {options.map((option) => (
        <button
          key={option.id}
          className={`switch-option ${selectedOption === option.id ? 'selected' : ''}`}
          onClick={() => onSelect(option)}
        >
          {option.display}
        </button>
      ))}
    </div>
  )
}

export default SwitchSelector
