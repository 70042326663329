import { useMemo } from 'react'
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiTitle, EuiSpacer } from '@elastic/eui'

import { useSelector } from 'react-redux'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import 'react-circular-progressbar/dist/styles.css'

const CountryVisitBox = ({ selectedSocial, setSelectedSocial }) => {
  const socials = useSelector((state) => state.socials.entities)

  const displaySocial = useMemo(() => {
    if (!selectedSocial) {
      return null
    }
    return socials[selectedSocial]
  }, [selectedSocial])

  const countryData = displaySocial?.audience_followers?.audience_geo?.countries || []

  if (!countryData.length) {
    return null
  }

  return (
    <div className="card p-4 shadow-sm flex-fill">
      <div className="d-flex flex-row">
        <EuiTitle size="xs">
          <h3>Visits by Country</h3>
        </EuiTitle>
      </div>
      <EuiSpacer size="m" />
      <div>
        {countryData.slice(0, 5).map((item, index) => (
          <div key={index}>
            <div className="d-flex justify-content-between align-items-center pb-1 geo-items">
              <EuiFlexItem grow={false}>
                <EuiFlexGroup className="py-1" gutterSize="s" alignItems="center">
                  <EuiFlexItem grow={false}>
                    <EuiText>{getUnicodeFlagIcon(item.code)}</EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiText className="fs-4">{item.name}</EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText>{`${Math.round(item.weight * 100)}%`}</EuiText>
              </EuiFlexItem>
            </div>
            <EuiSpacer size="s" />
          </div>
        ))}
      </div>
    </div>
  )
}

export default CountryVisitBox
