import './styles/process-fix.css'
import './styles/bootstrap.scss'
import './styles/index.scss'
import './styles/Table.scss'

import * as Sentry from '@sentry/react'

import { BrowserTracing } from '@sentry/tracing'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import store from './store'
// import Main from './Main'
import Main from './Main'
import { EuiProvider } from '@elastic/eui'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://7fdc407d4bc144568f5f9f55f21ae801@o144614.ingest.sentry.io/6325963',
    integrations: [new BrowserTracing()],

    tracesSampleRate: 0.25,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <EuiProvider colorMode="light">
      <Provider store={store}>
        <Main />
      </Provider>
    </EuiProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
