export class Talent {
  constructor(influencer, socials) {
    this.influencer = influencer
    this.socials = socials
    this.customDescription = influencer?.custom_description
  }

  getSocials() {
    const primaryAccount = this.socials[this.influencer.primary_account_id] || {}
    const secondaryAccounts = this.influencer.non_primary_accounts
      ? this.influencer.non_primary_accounts
          .map((id) => this.socials[id])
          .filter((social) => social)
      : []
    return {
      primaryAccount,
      secondaryAccounts,
      allSocials: [primaryAccount, ...secondaryAccounts],
    }
  }

  getDescription() {
    return this.influencer.custom_description || this.influencer.description || ''
  }

  getPhotoUrl() {
    if (!this.influencer)
      return 'https://tpp-content.us-east-1.linodeobjects.com/unknown-avatar.jpg'
    const primaryAccount = this.socials[this.influencer.primary_account_id]
    if (!primaryAccount)
      return 'https://tpp-content.us-east-1.linodeobjects.com/unknown-avatar.jpg'
    let portraitUrl = this.influencer.custom_picture || primaryAccount.picture_main_url
    if (!portraitUrl) {
      return 'https://tpp-content.us-east-1.linodeobjects.com/unknown-avatar.jpg'
    } else {
      return portraitUrl
    }
  }
}
