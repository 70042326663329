import React, { useState } from 'react'
import {
  EuiButton,
  EuiConfirmModal,
  EuiFlexGroup,
  EuiFlexItem,
  useGeneratedHtmlId,
} from '@elastic/eui'

export default ({
  isVisible,
  warningMessage,
  title,
  onCancel,
  onConfirm,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
}) => {
  const destroyModalTitleId = useGeneratedHtmlId()

  return (
    <>
      {isVisible && (
        <EuiConfirmModal
          aria-labelledby={destroyModalTitleId}
          title={title || `Are you sure?`}
          titleProps={{ id: destroyModalTitleId }}
          onCancel={onCancel}
          onConfirm={onConfirm}
          cancelButtonText={cancelText}
          confirmButtonText={confirmText}
          buttonColor="danger"
          defaultFocusedButton="confirm"
        >
          <p>{warningMessage}</p>
        </EuiConfirmModal>
      )}
    </>
  )
}
