import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  fetchSingleInfluencer,
  setTags,
  setTalentSheetReaction,
  setSheetNotes,
} from './redux/influencerSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import LoadingContainer from 'components/LoadingContainer'
import PublicProfile from './PublicProfile'
import PublicProfilev2 from 'PublicProfiles/v2/PublicProfile_v2'
import ReactMarkdown from 'react-markdown'
import _ from 'lodash'
import { addTags } from './redux/tagsSlice'
import axios from 'axios'
import { Talent } from 'helpers/Talent'
import { FaArrowLeft } from 'react-icons/fa'
import SocialIcon from 'components/SocialIcon'
import { toast, Slide } from 'react-toastify'
import { getDisplayName } from 'helpers/Social'
import { EuiButton } from '@elastic/eui'

export default function EditInfluencerPosts() {
  const navigate = useNavigate()

  const onBack = () => {
    navigate(-1)
  }

  const dispatch = useDispatch()
  const params = useParams()

  const showSavedToast = ({ customText = 'Changes saved' } = {}) => {
    toast.success(customText, {
      transition: Slide,
      position: 'top-center',
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
    })
  }

  const selectedInfluencerId = params.influencerId

  // const influencers = useSelector((state) => state.influencers.entities)
  const isLoading = useSelector((state) => state.influencers.loading === 'pending')

  const influencer = useSelector((state) => state.influencers.entities[selectedInfluencerId])
  const socials = useSelector((state) => state.socials.entities)
  const reduxSheets = useSelector((state) => state.allSheets.entities)
  const user = useSelector((state) => state.user)

  const [selectedSocialId, setSelectedSocialId] = useState(null)
  const [selectedPostCategory, setSelectedPostCategory] = useState('top_posts')
  const [excludedPostIds, setExcludedPostIds] = useState([])

  useEffect(() => {
    const excluded_post_ids = _.get(influencer, 'team_settings.excluded_post_ids', [])
    setExcludedPostIds(excluded_post_ids)
  }, [dispatch, influencer])

  const toggleExcludedPost = (postUrl) => {
    if (excludedPostIds.includes(postUrl)) {
      setExcludedPostIds(excludedPostIds.filter((url) => url !== postUrl))
    } else {
      setExcludedPostIds([...excludedPostIds, postUrl])
    }
  }

  useEffect(() => {
    dispatch(fetchSingleInfluencer({ influencer_id: params.influencerId }))
    // loadTeamNotes()
  }, [dispatch])

  if (_.isEmpty(influencer) || _.isEmpty(_.get(socials, influencer.primary_account_id))) {
    return <LoadingContainer />
  }

  if (!influencer || influencer.error) {
    return (
      <div className="container mt-3">
        <p>
          Error loading profile. Possibly you do not have access to this account, please contact us
          if you think you should have access
        </p>
      </div>
    )
  }

  const postCategories = [
    { name: 'Top Posts', value: 'top_posts' },
    { name: 'Top Reels', value: 'top_reels' },
    { name: 'Recent Posts', value: 'recent_posts' },
    { name: 'Sponsorships', value: 'commercial_posts' },
  ]

  const performSave = async () => {
    console.log(excludedPostIds)
    showSavedToast()
    // console.log(influencer.team_settings)

    const newSettings = {
      ...influencer.team_settings,
      excluded_post_ids: excludedPostIds,
    }

    console.log(newSettings)
    await axios
      .post('/api/team/influencer-settings', {
        influencer_id: params.influencerId,
        settings: newSettings,
      })
      .then(() => {
        dispatch(fetchSingleInfluencer({ influencer_id: params.influencerId }))
      })
  }

  const talent = new Talent(influencer, socials)
  const influencerSocials = talent.getSocials().allSocials
  // console.log(influencerSocials)

  const selectedSocial = influencerSocials.find((social) => social.id === selectedSocialId)
  const availablePostTypes = postCategories.filter((category) => {
    return selectedSocial ? !_.isEmpty(selectedSocial[category.value]) : false
  })

  const posts = _.get(socials, [selectedSocialId, selectedPostCategory]) || []
  // console.log(posts)

  // console.log('a', availablePostTypes)
  return (
    <div className="container influencer-post-editor">
      <div className="selectable mt-3">
        <span data-purpose="back-arrow" onClick={onBack}>
          <FaArrowLeft style={{ fontSize: '1.4em' }} />
        </span>
      </div>

      <div className="row mt-3">
        {influencerSocials.map((social) => {
          // console.log(social)
          return (
            <div className="col-4" key={`social-${social.id}`}>
              <div
                onClick={() => {
                  setSelectedSocialId(social.id)
                }}
                className={`card p-2 selectable ${
                  selectedSocialId === social.id ? 'text-bold selected' : ''
                }`}
              >
                <p className="text-center">
                  <SocialIcon platform={social.platform} /> {getDisplayName(social)}
                </p>
              </div>
            </div>
          )
        })}
      </div>
      <div className="row my-3">
        {availablePostTypes.map((category) => {
          // return null
          return (
            <div className="col-4" key={`social-${category.value}`}>
              <div
                onClick={() => {
                  setSelectedPostCategory(category.value)
                }}
                className="card selectable text-center p-2"
              >
                <p
                  className={`${
                    selectedPostCategory === category.value ? 'text-bold selected' : ''
                  }`}
                >
                  {category.name}
                </p>
              </div>
            </div>
          )
        })}
      </div>
      <hr />
      <div className="row">
        {!selectedSocial && (
          <div>
            <p>To hide/show individual posts, first choose an account (above)</p>
          </div>
        )}
        <div className="col-12">
          {selectedSocial && selectedSocial[selectedPostCategory].length > 0 && (
            <div className="row py-2">
              <p>Click on the thumbnails to prevent that post from being shown on the profile.</p>
              <p>Your changes will be applied when you click "Save"</p>
              <div className="my-3">
                <EuiButton onClick={performSave}>Save</EuiButton>
                <EuiButton
                  color={'danger'}
                  className="ms-2"
                  onClick={() => {
                    if (window.confirm('Applies to all platforms. Reset?')) {
                      setExcludedPostIds([])
                    }
                  }}
                >
                  Reset All
                </EuiButton>
              </div>
              {posts.slice(0, 12).map((post) => {
                return (
                  <div className="col-6 col-md-3 mb-3" key={`post-${post.post_id}`}>
                    <div
                      onClick={() => {
                        toggleExcludedPost(post.post_id)
                      }}
                      className="instagram-post-holder border shadow-sm selectable"
                    >
                      <div
                        className="image-holder"
                        style={{ backgroundImage: `url(${post.thumbnail})` }}
                      ></div>
                      <div
                        className={`text-holder ${
                          excludedPostIds.includes(post.post_id) ? 'show-holder' : ''
                        }`}
                      >
                        {!excludedPostIds.includes(post.post_id) && (
                          <p className="instagram-text text-black">{post.text}</p>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
