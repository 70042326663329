import NewCollectionList from 'NewCollectionList'
import NewTeamTalentPrivateRoster from 'NewTeamTalentPrivateRoster'
import SearchFavorites from './SearchFavorites'
import TagViewer from './TagViewer'
import TalentSheetList from './TalentSheetList'
import Conversations from 'Conversations'
import _ from 'lodash'
import TagHelper from 'TagHelper'
import NewTalentSheets from 'NewTalentSheets'

import { ReactComponent as Users } from 'assets/users.svg'
import { ReactComponent as FileIcon } from 'assets/file-05.svg'
import { ReactComponent as Grid } from 'assets/grid.svg'
import { ReactComponent as AnnouncementIcon } from 'assets/announcement-02.svg'
import NewCreatorCalls from 'NewCreatorCalls'

const HeaderIcon = ({ Icon, size = '22px' }) => {
  return <Icon className="me-1" style={{ width: size, height: size }} />
}

const routes = [
  // {
  //   title: 'Dashboard',
  //   route: '/dashboard',
  //   Icon: BsGraphUpArrow,
  //   element: <Dashboard />,
  //   hasNavlink: true,
  //   shouldShow: (user) => {
  //     return true
  //     return !_.get(user, 'teamSettings.noTalentSheets')
  //   },
  // },
  {
    title: 'Talent Roster',
    route: '/influencers',
    Icon: Users,
    element: <NewTeamTalentPrivateRoster />,
    hasNavlink: true,
    shouldShow: (user) => {
      return true
      return !_.get(user, 'teamSettings.noTalentSheets')
    },
  },
  {
    title: 'Talent Roster',
    route: '/influencers/tags',
    element: <TagViewer />,
    hasNavlink: true,
    shouldShow: (user) => {
      return false
      // return !_.get(user, 'teamSettings.noTalentSheets')
    },
  },
  {
    title: 'Talent Sheets',
    route: '/talent-sheets',
    Icon: FileIcon,
    element: <NewTalentSheets />,
    hasNavlink: true,
    shouldShow: (user) => {
      return !_.get(user, 'teamSettings.noTalentSheets')
    },
  },
  {
    title: 'Collections',
    route: '/collections',
    Icon: Grid,
    element: <NewCollectionList />,
    hasNavlink: true,
    shouldShow: (user) => {
      return !_.get(user, 'teamSettings.noTalentSheets')
    },
  },
  {
    title: 'Campaign Tracking',
    route: '/campaigns',
    element: <TalentSheetList sheetType="campaign" />,
    hasNavlink: true,
    shouldShow: (user) => {
      return false
      return _.get(user, 'teamSettings.hasCampaigns')
    },
  },
  // "Friends Search" would be entirely replaced by the new "Search" site
  // {
  //   title: 'Search',
  //   route: '/social-accounts',
  //   element: <AccountSearch showFavorites={true} />,
  //   hasNavlink: true,
  //   shouldShow: (user) => {
  //     return _.get(user, 'teamSettings.hasFriendsSearch')
  //   },
  // },
  {
    title: 'Favorites',
    route: '/favorites',
    element: <SearchFavorites />,
    hasNavlink: true,
    shouldShow: (user) => {
      return false
      // return _.get(user, 'teamSettings.hasFriendsSearch')
    },
  },
  {
    title: 'Messages',
    route: '/messages',
    element: <Conversations />,
    hasNavlink: false,
    shouldShow: false,
  },
  {
    title: 'Creator Calls',
    route: '/creator-calls',
    Icon: AnnouncementIcon,
    element: <NewCreatorCalls />,
    hasNavlink: true,
    shouldShow: (user) => {
      return _.get(user, 'teamSettings.hasCreatorCalls')
    },
  },
  {
    title: 'Tagger',
    route: '/tagging-assistant',
    element: <TagHelper />,
    hasNavlink: false,
    shouldShow: (user) => {
      return true
    },
  },
]

export default routes
