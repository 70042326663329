import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import TalentSheetBranding from './TalentSheetBranding'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import LoadingContainer from 'components/LoadingContainer'
import { useInView } from 'react-intersection-observer'
import { fetchSingleSheet } from 'redux/allSheetsSlice'
import mixpanel from './mixpanelBrowser'
import withRegistrationPopup from 'components/withRegistrationPopup'
import { Helmet } from 'react-helmet'

function PublicTalentSheet({
  talentSheet,
  orderedInfluencersForDisplay,
  socials,
  displaySettings,
  SheetProfile,
  loadNextGroup,
  talentLoading,
  setTalentLoading,
  totalPages,
  currentGroup,
}) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    onChange: (inView, entry) => {
      if (inView && !talentLoading) {
        setTalentLoading(true)
        loadNextGroup()
      }
    },
    initialInView: true,
  })

  return (
    <div>
      <TalentSheetBranding talentSheet={talentSheet} displaySettings={displaySettings} />
      {orderedInfluencersForDisplay.map((inf) => {
        return (
          <div className="" key={`influencer-${inf.id}`}>
            <SheetProfile
              influencerNote={talentSheet.notes[inf.id]}
              influencer={inf}
              socials={socials}
              influencerProfile={inf}
              settings={{
                ...talentSheet.settings,
                excluded_post_ids: _.get(inf, 'team_settings.excluded_post_ids', []),
              }}
              displaySettings={displaySettings}
              longView={true}
              oneSheet={true}
            />
          </div>
        )
      })}
      {currentGroup <= totalPages && (
        <div ref={ref} className="d-flex justify-content-center w-100 py-4">
          <button className="btn btn-primary" onClick={loadNextGroup}>
            {talentLoading ? 'Loading...' : 'Load More'}
          </button>
        </div>
      )}
    </div>
  )
}

export default withRegistrationPopup(PublicTalentSheet)
