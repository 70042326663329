import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import axios from 'axios'

interface UserState {
  account_id: string
  loading: 'idle' | 'pending'
  authed: boolean
  loaded: boolean
  id: number | null
  teams: any[]
  settings: Record<string, any>
  teamSettings: Record<string, any>
  favoriteInfluencers: any[]
  error: string | null
  userTeam: Record<string, any>
  email?: string | null
  has_search?: boolean
  primary_team_id: string
  selectedTeamId?: string
}

interface ProcessedStatusPayload {
  account_id: string
  user_id: string
  id: number
  teams: any[]
  teamSettings: Record<string, any>
  teamRole: string
}

export const fetchUserStatus = createAsyncThunk(
  'user/getStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/status')
      return response.data
    } catch (e) {
      throw rejectWithValue(
        'A server error occurred while trying to communicate with the server. If the problem persists, please contact support.',
      )
    }
  },
)

export const signIn = createAsyncThunk(
  'user/signIn',
  async ({ email, password }: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('/api/user/account/signin', {
        email,
        password,
      })
      return data
    } catch (err) {
      // Note: Assuming err.response.data contains server response
      // @ts-ignore
      return rejectWithValue(err.response.data)
    }
  },
)

export const signOut = createAsyncThunk('user/signOut', async () => {
  const response = await axios.get('/api/user/signout')
  return response.data
})

export const fetchSettings = createAsyncThunk('user/fetchSettings', async (hash_id: string) => {
  const response = await axios.get('/api/user/configuration')
  return response.data
})

export const fetchFavoriteInfluencers = createAsyncThunk(
  'user/fetchFavoriteInfluencers',
  async () => {
    const response = await axios.get('/api/search/influencers/favorites')
    return response.data
  },
)

const defaultInitialState: UserState = {
  account_id: '',
  loading: 'idle',
  authed: false,
  loaded: false,
  id: null,
  teams: [],
  settings: {},
  teamSettings: {},
  favoriteInfluencers: [],
  error: null,
  userTeam: {},
  email: localStorage.getItem('userEmail'),
  primary_team_id: '',
}

const processStatusPayload = (payload: any): ProcessedStatusPayload => {
  return {
    ...payload,
    account_id: payload.user_id,
    id: payload.user_id,
    primary_team_id: payload.selectedTeamId,
    teamSettings: payload.team?.settings || {},
    teamRole: payload.teamRole || '',
  }
}

const updateUserState = (state: UserState, payload: any) => {
  const processedPayload = processStatusPayload(payload)
  Object.assign(state, processedPayload)
  state.loaded = true
  state.loading = 'idle'
  state.teams = payload.teams
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    ...defaultInitialState,
  },
  reducers: {
    setUser: (state, { payload }: PayloadAction<any>) => {
      state = {
        ...state,
        ...processStatusPayload(payload),
        settings: state.settings,
        loaded: true,
      }
      return state
    },
    setEmail: (state, { payload }: PayloadAction<any>) => {
      state.email = payload
    },
    setTeams: (state, action: PayloadAction<any>) => {
      state.teams = action.payload
    },
    changeSetting: (state, { payload }: PayloadAction<{ name: string; value: any }>) => {
      state.settings[payload.name] = payload.value
    },
    toggleFavoriteInfluencer: (state, { payload }: PayloadAction<any>) => {
      if (state.favoriteInfluencers.includes(payload)) {
        state.favoriteInfluencers = state.favoriteInfluencers.filter((id) => id !== payload)
      } else {
        state.favoriteInfluencers.push(payload)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.fulfilled, (state, { payload }) => {
        state.settings = payload.user
        state.loaded = true
      })
      .addCase(fetchUserStatus.pending, (state) => {
        state.loading = 'pending'
      })
      .addCase(fetchUserStatus.fulfilled, (state, { payload }) => {
        return updateUserState(state, payload)
      })
      .addCase(signIn.fulfilled, (state, { payload }) => {
        return updateUserState(state, payload)
      })
      .addCase(signIn.rejected, (state, action) => {
        console.error('Sign in failed: ', action.error)
        state.error = action.error.message || null
      })
      .addCase(fetchUserStatus.rejected, (state, { error }) => {
        console.error('Fetch status failed: ', error)
        state.loading = 'idle'
        state.loaded = true
        state.error = error.message || null
      })
      .addCase(signOut.fulfilled, () => {
        return {
          ...defaultInitialState,
        }
      })
      .addCase(fetchFavoriteInfluencers.fulfilled, (state, { payload }) => {
        state.favoriteInfluencers = payload.favoriteInfluencers
      })
  },
})

// Action creators are generated for each case reducer function
export const { setUser, setTeams, changeSetting, toggleFavoriteInfluencer, setEmail } =
  userSlice.actions

export default userSlice.reducer
