import _, { map } from 'lodash'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { useEffect, useRef, useState, useMemo } from 'react'

import LoadingContainer from './components/LoadingContainer'
import { Rings } from 'react-loader-spinner'
import TalentSheetBranding from './TalentSheetBranding'
import PublicRosterHeading from 'components/PublicRosterHeading'
import {
  getContrastColor,
  getContrastingTextForBackground,
  joinClassNames,
  scaleColor,
  getColorHex,
} from './utils'
import { useInView } from 'react-intersection-observer'
import { Talent } from 'helpers/Talent'
import mixpanel from './mixpanelBrowser'
import { sendEvent } from 'analytics'
import Color from 'color'
import config from './config'
import ContactInfoModal from 'components/ContactInfoModal'
import { useSelector, useDispatch } from 'react-redux'
import { setModalShow } from 'redux/modalsSlice'
import withRegistrationPopup from 'components/withRegistrationPopup'
import { Helmet } from 'react-helmet'
import TalentSheetRosterItemFullCover from 'TalentSheetRosterItemFullCover'
import TalentSheetRosterItemCircular from 'TalentSheetRosterItemCircular'
import SocialIcon from 'components/SocialIcon'
import { safelyMillify } from 'utils'
import { PiGlobeStandBold } from 'react-icons/pi'
import UpwardGraph from 'assets/upward_graph.png'
import PersonalIcon from 'assets/personal_icon.png'
import AgeIcon from 'assets/age_icon.png'
import SwitchSelector from 'components/SwitchSelector'
import { setPublicRosterOrder } from 'redux/influencerSlice'
import { TbActivityHeartbeat } from 'react-icons/tb'
import { FaRegStar } from 'react-icons/fa'

function DisplayTalentCard({
  profile,
  handleClick,
  displaySettings = {
    displayRosterNameColor: 'black',
  },
  source = 'Talent Sheet',
}) {
  const picture_url = profile.custom_picture || profile.socials[0].picture_main_url
  const [overlayHeight, setOverlayHeight] = useState(null)
  const [overlayWidth, setOverlayWidth] = useState(null)
  const params = useParams()

  // We will use this to get the height of the image and set the height of the overlay, TODO
  const imageRef = useRef(null)
  const reflectionRef = useRef(null)

  useEffect(() => {
    function handleResize() {
      // If the window is currently being printed, return early
      if (window.matchMedia('print').matches) {
        return
      }

      setOverlayHeight(reflectionRef.current && reflectionRef.current.offsetHeight)
      setOverlayWidth(reflectionRef.current && reflectionRef.current.offsetWidth)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    setOverlayHeight(reflectionRef.current && reflectionRef.current.offsetHeight)
    setOverlayWidth(reflectionRef.current && reflectionRef.current.offsetWidth)
  }, [reflectionRef])

  let nameFontModifier = null
  if (profile.fullname?.length > 18) nameFontModifier = 'fs-5'
  if (profile.fullname?.length > 30) nameFontModifier = 'fs-5'

  let customNameFont =
    displaySettings.displayTalentNameFont || displaySettings.displayRosterTitleFont || 'barlow'

  const lowerDisplayStyles = {
    backgroundImage: `url(${picture_url})`,
  }

  if (displaySettings.displayPortraitsBW) {
    lowerDisplayStyles.filter = 'grayscale(100%)'
    lowerDisplayStyles.transition = 'filter 0.5s ease'
  }

  const totalFollowers = useMemo(() => {
    return profile.socials.reduce((acc, social) => {
      if (!social.followers) return acc

      return acc + social.followers
    }, 0)
  }, [profile.socials])

  const iconSize = '26px'
  const metricFontSize = '1.05em'

  return (
    <div data-influencer-id={profile.id} className="col-md-6 col-lg-4 mb-4">
      <div
        className="card p-3"
        style={{
          borderRadius: '15px',
        }}
      >
        <div className="">
          <img
            style={{
              width: '100%',
              borderRadius: '15px',
            }}
            ref={imageRef}
            onClick={() => {
              handleClick()
            }}
            className={joinClassNames([`selectable`])}
            src={picture_url}
          />
          <div className="">
            <div>
              <div className="d-flex flex-column justify-content-around pt-2">
                <div className="mb-2 row">
                  <div className="col-12 col-md-8">
                    <div
                      onClick={() => {
                        handleClick()
                      }}
                      className={joinClassNames([
                        `selectable`,
                        'color-black',
                        // 'text-center',
                        // nameFontModifier,
                        'fw-bold',
                        'm-0',
                        // `influencer-name`,
                      ])}
                    >
                      {profile.fullname}
                    </div>
                    <div style={{ fontSize: '1em', minHeight: '1.5em' }} className="">
                      {profile.tagline}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 d-flex flex-column">
                    <div style={{ fontSize: '1em', minHeight: '1.5em' }} className="fw-bold">
                      {safelyMillify(totalFollowers)}{' '}
                      <PiGlobeStandBold className="ms-1" size={18} color="#26aefb" />
                    </div>
                    <div className="">Total Reach</div>
                  </div>
                </div>

                <div className="d-flex flex-column">
                  <div
                    className={joinClassNames([
                      `d-flex flex-wrap justify-content-around`,
                      `align-items-center social-follower-counts text-center mb-0`,
                      'bg-subdued',
                      'py-3',
                      'rounded',
                    ])}
                  >
                    {profile.socials.map((social, i) => {
                      return (
                        <div
                          className="d-flex flex-column align-items-center mb-0 roster-social-icon py-0 roster-social-display"
                          key={`profile-${social.id}`}
                        >
                          <div className="mb-0">
                            <a
                              className="text-black"
                              onClick={() => {
                                sendEvent('social_view', {
                                  influencer_id: profile.id,
                                  platform: social.platform,
                                  social_id: social.id,
                                  sheet_id: params.talentSheetId,
                                  team_id: params.teamId,
                                  from_roster: source === 'Public Roster',
                                })
                              }}
                              href={social.profile_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <SocialIcon size="20" platform={social.platform} />
                            </a>
                            <span
                              className="ms-1"
                              style={{
                                borderBottom: '2px solid black',
                              }}
                            >
                              {safelyMillify(social.followers)}
                            </span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 col-md-4 d-flex flex-column">
                    <div>
                      <img
                        style={{
                          height: iconSize,
                          width: iconSize,
                        }}
                        src={UpwardGraph}
                        alt="Upward Graph"
                      />
                    </div>

                    {profile?.primary_engagement_rate &&
                      Number(profile.primary_engagement_rate) > 0 && (
                        <>
                          <div
                            style={{
                              fontSize: metricFontSize,
                            }}
                            className="fw-bold"
                          >
                            {profile.primary_engagement_rate}%
                          </div>
                          <div className="text-gray">Engagement Rate</div>
                        </>
                      )}
                  </div>
                  <div className="col-12 col-md-4">
                    <div>
                      <img
                        style={{
                          height: iconSize,
                          width: iconSize,
                        }}
                        src={PersonalIcon}
                        alt="Personal Icon"
                      />
                    </div>
                    <div
                      style={{
                        fontSize: metricFontSize,
                      }}
                      className="fw-bold"
                    >
                      {Math.round(Number(profile.primary_female_audience))}% Female
                    </div>
                    <div className="text-gray">Audience Type</div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div>
                      <img
                        style={{
                          height: iconSize,
                          width: iconSize,
                        }}
                        src={AgeIcon}
                        alt="Age Icon"
                      />
                    </div>
                    <div
                      style={{
                        fontSize: metricFontSize,
                      }}
                      className="fw-bold"
                    >
                      {profile?.primary_age_group?.percentage}
                      {'% '}
                      {profile?.primary_age_group?.age_group} yrs
                    </div>
                    <div className="text-gray">Audience Age</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            zIndex: 2,
          }}
          className="d-flex flex-column justify-content-end roster-box shadow selectable"
        ></div>
      </div>
    </div>
  )
}

const NewRenderPublicRoster = ({
  talentSheet,
  socials,
  displayedInfluencers,
  totalPages = 1,
  currentGroup,
  pageLimit,
  talentLoading,
  setTalentLoading,
  sheetIsLoading,
  loadNextGroup,
  source = 'Talent Sheet',
  displaySettings = {
    displayAccentColor: 'shine-gold',
  },
}) => {
  const params = useParams()
  const navigate = useNavigate()
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    onChange: (inView, entry) => {
      if (inView && !talentLoading) {
        setTalentLoading(true)
        loadNextGroup()
      }
    },
    initialInView: true,
  })

  const mainContainerRef = useRef(null)
  const [textColorClass, setTextColorClass] = useState('text-white')

  const dispatch = useDispatch()

  const publicRosterOrder = useSelector((state) => state.influencers.publicRosterOrder)

  const showContactInfo = useSelector((state) => {
    return state.modals.contactInfo === true
  })

  const backgroundColorStyle = useMemo(() => {
    let bgColorHex
    if (displaySettings.displayRosterBackgroundColor) {
      bgColorHex = getColorHex(displaySettings.displayRosterBackgroundColor)
      return { backgroundColor: bgColorHex }
    }

    bgColorHex = getColorHex(displaySettings.displayAccentColor)
    return { backgroundColor: scaleColor(bgColorHex, 92) }
  }, [displaySettings])

  useEffect(() => {
    // Use bgColorHex from backgroundColorStyle, falling back to 'white' if not set.
    const bgColorHex = backgroundColorStyle.backgroundColor || 'white'
    const contrastingTextColor = getContrastingTextForBackground(bgColorHex)
    setTextColorClass(`text-${contrastingTextColor}`)
  }, [backgroundColorStyle])

  const pageTitle = useMemo(() => {
    if (talentSheet?.description) return `${talentSheet.description} - Talent Pitch Pro`
    if (displaySettings?.name) return `Talent Sheet from ${displaySettings.name}`
    return `Talent Pitch Pro`
  }, [talentSheet])

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PublicRosterHeading displaySettings={displaySettings} talentSheet={talentSheet} />
      <div
        ref={mainContainerRef}
        className={joinClassNames([
          // typeof backgroundColorStyle === 'string' ? backgroundColorStyle : null,
          // 'bg-subdued',
          'bg-subdued',
          'public-roster-container',
        ])}
        // style={backgroundColorStyle}
      >
        <div className="container">
          <div className="d-flex flex-column flex-md-row justify-content-between">
            <h4 className={joinClassNames([`text-center`, 'fs-3', `mb-3 py-3`, 'd-print-none'])}>
              Click any portrait to view full profile
            </h4>

            <div>
              <SwitchSelector
                options={[
                  {
                    id: 'engagement',
                    display: (
                      <>
                        <TbActivityHeartbeat size={20} />
                        <span className="ms-1">Engagement Rate</span>
                      </>
                    ),
                  },
                  {
                    id: 'followers',
                    display: (
                      <>
                        <FaRegStar size={16} />
                        <span className="ms-1">Followers</span>
                      </>
                    ),
                  },
                  {
                    id: 'default',
                    display: 'Default',
                  },
                ]}
                selectedOption={publicRosterOrder}
                onSelect={(option) => {
                  dispatch(setPublicRosterOrder(option.id))
                }}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className={joinClassNames([
              `row`,
              displaySettings.displayUseRosterFullPhoto ? 'gx-5' : null,
            ])}
          >
            {_.isEmpty(displayedInfluencers) && (
              <div className="d-flex justify-content-center">
                <Rings
                  height="80"
                  width="80"
                  color="#5863f8ff"
                  radius="6"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="rings-loading"
                />
              </div>
            )}
            {map(displayedInfluencers, (influencer, i) => {
              const talent = new Talent(influencer, socials)
              const profile = {
                ...influencer,
              }
              profile.socials = talent.getSocials().allSocials

              return (
                <DisplayTalentCard
                  key={`influencer-${influencer.id}`}
                  specialText={_.get(talentSheet, ['notes', influencer.id], null)}
                  influencer={influencer}
                  handleClick={() => {
                    mixpanel.track(`${source} - Clicked Influencer`, {
                      influencer_id: influencer.id,
                      talent_sheet_id: params.talentSheetId,
                    })
                    sendEvent('sheet_click_influencer', {
                      influencer_id: influencer.id,
                      sheet_id: params.talentSheetId,
                      team_id: params.teamId,
                    })
                    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
                    navigate(`?influencer_id=${influencer.id}`)
                  }}
                  profile={profile}
                  displaySettings={_.get(talentSheet, 'settings', {})}
                  source={source}
                />
              )
            })}
          </div>
        </div>
        {totalPages > 1 && currentGroup * pageLimit <= talentSheet.influencer_ids.length && (
          <div ref={ref} className="d-flex justify-content-center w-100 pb-4">
            <button className="btn btn-primary" onClick={loadNextGroup}>
              {talentLoading ? 'Loading...' : 'Load More'}
            </button>
          </div>
        )}
        {sheetIsLoading && <LoadingContainer />}
        <ContactInfoModal
          showModal={showContactInfo}
          handleClose={() => dispatch(setModalShow({ modal: 'contactInfo', show: false }))}
          logoUrl={talentSheet?.team_image_url}
          email={talentSheet?.team_contact_email}
          phoneNumber={talentSheet?.team_phone}
          homepage={talentSheet?.team_homepage}
          bio={talentSheet?.team_bio}
        />
      </div>
    </>
  )
}

export default withRegistrationPopup(NewRenderPublicRoster)
