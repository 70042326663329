import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Bar, Doughnut } from 'react-chartjs-2'

import ChartDataLabels from 'chartjs-plugin-datalabels'
import _ from 'lodash'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { safelyMillify } from '../utils'

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
ChartJS.register(ChartDataLabels)

function GenderChart({ audience_followers }) {
  const genderData = _.get(audience_followers, 'audience_gender', [])

  if (_.isEmpty(genderData)) {
    return 'No data available'
  }

  const malePct = Math.round(100 * _.find(genderData, (gd) => gd.code === 'MALE').weight)
  const femalePct = Math.round(100 * _.find(genderData, (gd) => gd.code === 'FEMALE').weight)

  const data = {
    labels: ['Male', 'Female'],
    datasets: [
      {
        label: '# of Votes',
        data: [malePct, femalePct],
        backgroundColor: ['rgba(54, 162, 235, 0.2)', 'rgba(255, 99, 132, 0.2)'],
        borderColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)'],
        borderWidth: 1,
      },
    ],
  }

  return (
    <div className="gender-chart-container">
      <Doughnut data={data} options={{ radius: '100%' }} />
    </div>
  )
}

function AgeChart({ audience_followers }) {
  const ageData = _.get(audience_followers, 'audience_age', []).filter((ad) => {
    return Math.round(ad.weight * 100) > 0
  })

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'start',
        offset: -18,
        // color: '#36A2EB',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  }

  const data = {
    labels: ageData.map((ad) => ad.code),
    datasets: [
      {
        label: 'Audience %',
        data: ageData.map((ad) => Math.round(ad.weight * 100)),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  }

  return (
    <>
      <h4 className="text-center">Audience Percentage By Age</h4>
      <Bar options={options} data={data} />
    </>
  )
}

export default function Infobox({ infoKey, audience_followers, advanced_data }) {
  const ShowRate = ({ n, title }) => {
    if (!n) {
      return null
    }
    let displayNumber = `${Number(n).toFixed(2)}%`
    // if (displayNumber.slice(-2)[0] === '.') {
    //   displayNumber = displayNumber.slice(0, -1)
    // }

    return (
      <>
        <h4 className="text-center">{title}</h4>
        <h3 className="text-center pt-3">
          {displayNumber} <span style={{ fontSize: '.55em' }}></span>
        </h3>
      </>
    )
  }

  function ShowNumber({ n, title }) {
    if (!n) {
      return null
    }
    return (
      <>
        <h4 className="text-center">{title}</h4>
        <h3 className="text-center pt-3">{safelyMillify(n)}</h3>
      </>
    )
  }

  let content
  switch (infoKey) {
    case 'audience_gender':
      content = (
        <>
          <h4 className="text-center">Audience Gender</h4>
          <div className="d-flex justify-content-center">
            <GenderChart audience_followers={audience_followers} />
          </div>
        </>
      )
      break
    case 'audience_age':
      content = (
        <>
          <AgeChart audience_followers={audience_followers} />
        </>
      )
      break
    case 'audience_countries':
      content = (
        <>
          <h4 className="text-center">Top Countries</h4>
          <ol className="country-list">
            {_.get(audience_followers, 'audience_geo.countries', [])
              .slice(0, 5)
              .map((ag) => {
                return (
                  <li className="pb-1" key={`${ag.code}`}>
                    <span className="ps-2">
                      {getUnicodeFlagIcon(ag.code)} {ag.name}: {`${Math.round(ag.weight * 100)}%`}
                    </span>
                  </li>
                )
              })}
          </ol>
        </>
      )
      break
    case 'audience_interests':
      content = (
        <>
          <h4 className="text-center">Audience Interests</h4>

          <p>
            {_.get(audience_followers, 'audience_interests', [])
              .slice(0, 5)
              .map((ai) => ai.name)
              .join(', ')}
          </p>
        </>
      )
      break
    case 'audience_cities':
      content = (
        <>
          {' '}
          <h4 className="text-center">Top Cities</h4>
          {_.get(audience_followers, 'audience_geo.cities', [])
            .slice(0, 5)
            .map((ag, i) => {
              return (
                <p key={`city-${i}`}>
                  {ag.name} {/* {ag.country.code !== 'US' ? `, ${ag.country.name}` : ''}:{' '} */}
                  {`${Math.round(ag.weight * 100)}%`}
                </p>
              )
            })}
        </>
      )
      break
    case 'avg_views':
      if (!advanced_data || _.isEmpty(advanced_data.avg_views)) {
        return null
      }
      content = <ShowNumber title={'Average Views'} n={_.get(advanced_data, 'avg_views')} />
      break
    case 'avg_comments':
      if (!advanced_data || _.isEmpty(advanced_data.avg_comments)) {
        return null
      }
      content = <ShowNumber title={'Average Comments'} n={_.get(advanced_data, 'avg_comments')} />
      break
    case 'engagement_rate':
      if (!advanced_data || _.isEmpty(advanced_data.engagement_rate)) {
        return null
      }
      content = <ShowRate title={'Engagement Rate'} n={_.get(advanced_data, 'engagement_rate')} />
      break
    case 'avg_likes':
      if (!advanced_data || _.isEmpty(advanced_data.avg_likes)) {
        return null
      }
      content = <ShowNumber title={'Average Likes'} n={_.get(advanced_data, 'avg_likes')} />
      break
    case 'avg_reels_plays':
      if (!advanced_data || !advanced_data?.avg_reels_plays) {
        return null
      }
      content = (
        <ShowNumber title={'Average Reels Plays'} n={_.get(advanced_data, 'avg_reels_plays')} />
      )
      break
    default:
      return null
  }
  return <div className="">{content}</div>
}
