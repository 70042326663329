import React, { useState } from 'react'
import './grid.scss'
import { Card, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Talent } from 'helpers/Talent'
import { setInfluencersAndSave } from 'redux/userSheetSlice'

const RenderGridPanel = ({ influencer }) => {
  const socials = useSelector((state) => state.socials.entities)

  const talent = new Talent(influencer, socials)
  const portraitURL = talent.getPhotoUrl()
  const talentName = talent.influencer?.fullname

  return (
    <Card className="shadow position-relative">
      <Card.Img
        variant="top"
        src={portraitURL}
        style={{ borderRadius: '0', width: '100%', height: '100%' }}
      />
      <Card.ImgOverlay className="d-flex align-items-end justify-content-center p-0">
        <Card.Title className="text-center w-100 bg-white text-black py-2 mb-0">
          {talentName}
        </Card.Title>
      </Card.ImgOverlay>
    </Card>
  )
}

const DraggableGrid = ({ influencer_ids }) => {
  const dispatch = useDispatch()

  const [gridItems, setGridItems] = useState(influencer_ids)
  const [dragOver, setDragOver] = useState(null) // Add this line

  const influencers = useSelector((state) => state.influencers.entities)

  const handleDragStart = (index) => (event) => {
    event.dataTransfer.setData('dragIndex', index)

    // Assume the draggable element is the first child of the target element
    // const draggableElement = event.target.children[0]
    // const dragImage = draggableElement.cloneNode(true)
    // dragImage.style.width = '200px'
    // dragImage.style.height = '200px'
    // dragImage.style.transform = 'scale(0.5)'
    // document.body.appendChild(dragImage)

    // event.dataTransfer.setDragImage(dragImage, 0, 0)

    // // Remove the drag image from the DOM after a short delay
    // setTimeout(() => {
    //   document.body.removeChild(dragImage)
    // }, 0)
  }

  const handleDragOver = (index) => (event) => {
    event.preventDefault()
    setDragOver(index) // Add this line
  }

  const handleDragLeave = () => {
    setDragOver(null) // Add this line
  }

  const handleDrop = (index) => (event) => {
    event.preventDefault()
    const draggedIndex = parseInt(event.dataTransfer.getData('dragIndex'), 10)
    if (draggedIndex !== index) {
      const updatedItems = [...influencer_ids]
      const [draggedItem] = updatedItems.splice(draggedIndex, 1)
      updatedItems.splice(index, 0, draggedItem)
      dispatch(setInfluencersAndSave(updatedItems))
    }
    setDragOver(null)
  }

  const preventDefault = (event) => {
    event.preventDefault()
  }

  return (
    <div id="grid-container">
      {influencer_ids.map((item, index) => {
        const influencer = influencers[item]

        return (
          <div
            key={index}
            className={`grid-item ${dragOver === index ? 'drag-over' : ''}`} // Modify this line
            draggable
            onDragStart={handleDragStart(index)}
            onDragOver={handleDragOver(index)}
            onDragLeave={handleDragLeave} // Add this line
            onDrop={handleDrop(index)}
          >
            <RenderGridPanel influencer={influencer} />
          </div>
        )
      })}
    </div>
  )
}

export default DraggableGrid
