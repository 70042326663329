import { ErrorBoundary } from 'react-error-boundary'
import { useNavigate, useLocation, Link, useRouteError } from 'react-router-dom'

function ErrorFallback({ error, resetErrorBoundary }) {
  console.log('should return next')
}

const ErrorWrapper = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  let error = useRouteError()
  console.error(error)

  return (
    <div className="container mt-3" role="alert">
      <h2 className="my-3">Talent Pitch Pro</h2>
      <p>We're sorry! Something went wrong while trying to access this page.</p>
      <p>
        If you keep getting an error, please <Link to="/contact-tpp">contact us</Link> to let us
        know what is happening.
      </p>
      <p>
        If you are unable to access the site at all, try logging out by following{' '}
        <a href="https://app.talentpitchpro.com/signout">this link</a>, then logging back in again.
        If the problem persists, you can try using the{' '}
        <a href="https://legacy.talentpitchpro.com">alternative site</a>.
      </p>
      <p>
        <span
          onClick={() => {
            navigate(-1)
          }}
          className="btn btn-link"
        >
          Click here
        </span>
        to go back to the previous page you were on
      </p>
    </div>
  )
}

export default ErrorWrapper
