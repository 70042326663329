import React, { useState } from 'react'
import './grid.scss'
import { Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

// const RenderGridPanel = ({ item }) => {
//   return (
//     <Card className="shadow position-relative">
//       <Card.Img
//         variant="top"
//         src={item.image}
//         style={{ borderRadius: '0', width: '100%', height: '100%' }}
//       />
//       <Card.ImgOverlay className="d-flex align-items-end justify-content-center p-0">
//         <Card.Title className="text-center w-100 bg-white text-black py-2 mb-0">
//           {item.name}
//         </Card.Title>
//       </Card.ImgOverlay>
//     </Card>
//   )
// }

const DraggableGrid = ({ items, onDropComplete, RenderGridPanel }) => {
  console.log('items', items)
  const [gridItems, setGridItems] = useState(items)
  const [dragOver, setDragOver] = useState(null)

  const dispatch = useDispatch()

  const handleDragStart = (index) => (event) => {
    event.dataTransfer.setData('dragIndex', index)
  }

  const handleDragOver = (index) => (event) => {
    event.preventDefault()
    setDragOver(index)
  }

  const handleDragLeave = () => {
    setDragOver(null)
  }

  const handleDrop = (index) => (event) => {
    event.preventDefault()
    const draggedIndex = parseInt(event.dataTransfer.getData('dragIndex'), 10)
    if (draggedIndex !== index) {
      const updatedItems = [...gridItems]
      const [draggedItem] = updatedItems.splice(draggedIndex, 1)
      updatedItems.splice(index, 0, draggedItem)
      setGridItems(updatedItems)
      onDropComplete(updatedItems)
    }
    setDragOver(null)
  }

  return (
    <div
      style={{
        backgroundColor: 'transparent',
      }}
      id=""
    >
      {gridItems.map((item, index) => (
        <div
          key={`sheet-${item}`}
          className={`grid-item ${dragOver === index ? 'drag-over' : ''}`}
          draggable
          onDragStart={handleDragStart(index)}
          onDragOver={handleDragOver(index)}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop(index)}
        >
          <RenderGridPanel item={item} />
        </div>
      ))}
    </div>
  )
}

export default DraggableGrid
