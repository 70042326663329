import React, { useState } from 'react'
import './switch.scss'
import { BsFillGrid3X3GapFill } from 'react-icons/bs'
import { BsListUl } from 'react-icons/bs'

const Switch = ({ isRight: isLeft, setIsRight: setIsLeft, ChoiceLeft, ChoiceRight }) => {
  return (
    <div className="switch-container">
      <button
        className={`switch-button ${isLeft ? 'active' : ''}`}
        onClick={() => setIsLeft(false)}
      >
        <BsListUl />
        <span className="ms-1">List View</span>
      </button>
      <button
        className={`switch-button ${!isLeft ? 'active' : ''}`}
        onClick={() => setIsLeft(true)}
      >
        <BsFillGrid3X3GapFill />
        <span className="ms-1">Grid View</span>
      </button>
    </div>
  )
}

export default Switch
