import { BiUpArrowAlt, BiDownArrowAlt } from 'react-icons/bi'
import config from 'config'

const DisplayChange = ({ change }) => {
  return (
    <div className={change > 0 ? 'text-success' : 'text-danger'}>
      <span
        style={{
          backgroundColor: change > 0 ? config.colors['light-green'] : config.colors['light-red'],
          padding: '4px',
          borderRadius: '4px',
        }}
      >
        {change > 0 ? (
          <BiUpArrowAlt className="text-success" />
        ) : (
          <BiDownArrowAlt className="text-danger" />
        )}
        {change.toFixed(2).replace('-', '')}%
      </span>
    </div>
  )
}

export default DisplayChange
