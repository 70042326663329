import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  addSheetToCollection,
  fetchCollection,
  setSalesSheets,
  setTitle,
} from './redux/collectionsSlice'
import {
  fetchBasicInfo,
  fetchSheetsForCollection,
  fetchSheetsType,
  // updateSheetCategories,
  updateSheetDescription,
  fetchSingleSheet,
} from './redux/allSheetsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import Button from 'react-bootstrap/Button'
import { FaEdit } from 'react-icons/fa'
import { Fragment } from 'react'
import LoadingContainer from './components/LoadingContainer'
import Modal from 'react-bootstrap/Modal'
import _ from 'lodash'
import axios from 'axios'
import { Talent } from './helpers/Talent'
import { toast, Slide } from 'react-toastify'
import InfoPopover from 'components/InfoPopover'

function TitleModal({ show, handleClose }) {
  const [title, setTitle] = useState('')

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Title</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enter a public display title for the sheet</p>
          <p>
            <span className="text-danger">WARNING:</span>Any changes to the public display title
            will be applied everywhere this sheet is viewed in "Roster View"
          </p>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleClose(title)
            }}
          >
            <input
              id="modal-sheet-title-input"
              autoFocus
              type="text"
              className="form-control"
              placeholder="Sheet Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <button type="submit" hidden />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-purpose="set-title"
            onClick={() => {
              handleClose(title)
            }}
            variant="primary"
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

function CollectionTalentSheetItem({ sheet_id, handleRemove }) {
  const dispatch = useDispatch()
  const [showTitleModal, setShowTitleModal] = useState(false)

  const handleCloseModal = (newTitle) => {
    const savePublicSheetHeading = (newHeading, sheet_id) => {
      return axios.post('/api/user/talent-sheets/public-title', {
        public_title: newHeading,
        sheet_id,
      })
    }

    if (!_.isEmpty(newTitle)) {
      savePublicSheetHeading(newTitle, sheet_id).then((res) => {
        dispatch(updateSheetDescription({ id: sheet_id, description: newTitle }))
      })
    }
    setShowTitleModal(false)
  }

  const talentSheet = useSelector((state) => {
    const currentSheet = state.allSheets.entities[sheet_id]
    return currentSheet
  })

  const sheetInfluencers = useSelector((state) => {
    return talentSheet.influencer_ids.map((influencer_id) => {
      return state.influencers.entities[influencer_id]
    })
  })
  const socials = useSelector((state) => {
    return state.socials.entities
  })

  if (_.isEmpty(socials)) {
    return <LoadingContainer />
  }

  return (
    <div
      data-sheet-id={talentSheet.id}
      className="col-12 roster-influencer-holder collection-sheet-preview mb-4"
    >
      <div
        style={{ height: '100%' }}
        className="d-flex flex-column justify-content-between p-3 roster-box bg-white rounded shadow"
      >
        <div
          className="roster-name text-center influencer-name mb-3"
          onClick={() => setShowTitleModal(true)}
        >
          {talentSheet.description ? (
            <>
              <span>{talentSheet.description}</span>
              <span className="ms-3 selectable">
                <FaEdit />
              </span>
            </>
          ) : (
            <span style={{ fontStyle: 'italic' }} className="text-italic">
              Public Title Needed ({talentSheet.title})
            </span>
          )}
        </div>
        <div className="container">
          <div className="d-flex flex-row justify-content-center">
            <div className="row w-50">
              <div className="d-flex flex-row justify-content-center">
                {sheetInfluencers.slice(0, 3).map((influencer, i) => {
                  if (!influencer) return null

                  const talent = new Talent(influencer, socials)

                  return (
                    <div key={`avatar-${i}`} className="px-2">
                      <div className="portrait-rounded text-center">
                        <div className="">
                          <Link to={`/talent-sheet/${sheet_id}`}>
                            <img src={talent.getPhotoUrl()} />
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-end">
          <button onClick={() => handleRemove()} className="btn btn-danger">
            Remove
          </button>
        </div>
      </div>
      <TitleModal show={showTitleModal} handleClose={handleCloseModal} />
    </div>
  )
}

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
})

const sheetToDraggable = (sheet) => {
  return {
    id: `sheet-${sheet.id}`,
    content: {
      title: sheet.title,
      id: sheet.id,
      influencer_ids: sheet.influencer_ids,
    },
  }
}

export default function EditCollection() {
  const navigate = useNavigate()
  const params = useParams()

  const user = useSelector((state) => state.user)

  const collection = useSelector((state) => {
    let collectionId = params.collectionId
    return state.collections.collections[collectionId]
  })

  const talentSheets = useSelector((state) => state.allSheets.entities)

  const [saved, setSaved] = useState(null)
  const [error, setError] = useState(null)

  const [editTitle, setEditTitle] = useState(true)
  const [showTitleModal, setShowTitleModal] = useState(false)
  const [showCopySheetModal, setShowCopySheetModal] = useState(false)

  const [sortColumn, setSortColumn] = useState(null)
  const [sortDirection, setSortDirection] = useState('desc')

  const [sheetTitleFilter, setSheetTitleFilter] = useState('')

  const dispatch = useDispatch()

  useEffect(async () => {
    const load = async () => {
      if (params.collectionId !== 'new-collection') {
        dispatch(fetchCollection({ collection_id: params.collectionId }))
        setEditTitle(false)
      }
    }

    load()
  }, [dispatch, params.collectionId])

  useEffect(() => {
    dispatch(fetchSheetsForCollection({}))
  }, [dispatch])

  useEffect(() => {
    const getData = async () => {
      return dispatch(fetchSheetsForCollection({ title_filter: sheetTitleFilter }))
    }

    let timer = setTimeout(() => {
      if (!_.isEmpty(sheetTitleFilter) && sheetTitleFilter.length > 2) getData()
    }, 1200)

    // If useEffect() relaunches, you clear the function
    // That means, the previous function won't launch
    // Thus, won't send a request to the API
    return () => clearTimeout(timer)
  }, [sheetTitleFilter])

  if (!collection || collection.loading) {
    return <div>Loading...</div>
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const usedSheets = _.map(collection.salesheet_ids)

  let draggableCollectionSheets = _.chain(collection.salesheet_ids)
    .map((sheet_id) => {
      return talentSheets[sheet_id]
    })
    .filter((sheet) => sheet)
    .map(sheetToDraggable)
    .value()

  const showSavedToast = ({ customText = 'Collection Saved' } = {}) => {
    toast.success(customText, {
      transition: Slide,
      position: 'top-center',
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
    })
  }

  let handleSave = () => {
    const confirmCollectionSaveable = (collection) => {
      if (collection.salesheet_ids.length === 0) {
        return false
      }
      // all sheets have to have public titles (description)
      let allSheetsHavePublicTitles = _.every(collection.salesheet_ids, (sheet_id) => {
        return !_.isEmpty(talentSheets[sheet_id].description)
      })
      if (!allSheetsHavePublicTitles) {
        return false
      }
      // collection needs a title
      if (_.isEmpty(collection.title)) {
        return false
      }

      return true
    }

    const data = {
      ...collection,
    }

    if (params.collectionId !== 'new-collection') {
      data.collection_id = params.collectionId
    }

    const saveable = confirmCollectionSaveable(data)

    if (!saveable) {
      setError(
        'Please check to be sure the collection has a title, and all individual sheets you included have a public title',
      )
      return
    } else {
      setError(null)
    }

    axios
      .post('/api/user/collections/save', data)
      .then((res) => {
        if (params.collectionId === 'new-collection') {
          const navTo = `/collection/${res.data.collection_id}`
          navigate(navTo)
        } else {
          showSavedToast()
        }
      })
      .catch((e) => {
        const error = _.get(e, 'response.data.error')
        setError(error)
        window.setTimeout(() => {
          setError(null)
        }, 2500)
      })
  }

  const handleAdd = async (sheet_id) => {
    dispatch(
      fetchSingleSheet({
        hash_id: sheet_id,
        with_stats: false,
        limit: 4,
      }),
    )

    dispatch(
      addSheetToCollection({
        sheet_id,
        collection_id: collection.id || 'new-collection',
      }),
    )
  }

  const handleRemove = (sheet_id) => {
    const nextSheets = collection.salesheet_ids.filter((id) => id !== sheet_id)

    dispatch(
      setSalesSheets({
        collection_id: params.collectionId,
        salesheet_ids: nextSheets,
      }),
    )
  }

  function onDragEnd(result) {
    const { source, destination } = result

    if (!destination) {
      return
    }
    const sInd = +source.droppableId
    const dInd = +destination.droppableId

    if (sInd === dInd) {
      const newSalesSheetOrder = reorder(collection.salesheet_ids, source.index, destination.index)

      dispatch(
        setSalesSheets({
          collection_id: collection.id || 'new-collection',
          salesheet_ids: newSalesSheetOrder,
        }),
      )
    }
  }

  const SaveButton = () => {
    return (
      <div className="d-flex flex-row align-content-center my-2">
        <button
          onClick={() => {
            handleSave()
          }}
          className="btn btn-primary my-2"
          data-purpose="save-collection"
          disabled={usedSheets.length === 0}
        >
          Save Collection
        </button>
        <div className={`message-box ${error ? 'error' : ''}`}>
          {error && (
            <div className="ms-4">
              <p>{error}</p>
            </div>
          )}
          {saved && (
            <div className="success ms-4">
              <p>Saved!</p>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="container pt-3">
      {params.collectionId === 'new-collection' && (
        <h2 className="text-center mb-3">New Collection</h2>
      )}

      <div className="my-1">
        {params.collectionId !== 'new-collection' && (
          <div className="row">
            <p className="col-6" style={{ fontSize: '1.1em' }}>
              <Link
                data-purpose="roster-view-public-collection-link"
                to={`/public/collection/${params.collectionId}`}
              >
                Public Link for Sharing
              </Link>
            </p>
            <p className="col-6" style={{ fontSize: '1.1em' }}>
              <Link
                data-purpose="roster-view-public-collection-link"
                to={`/public/collection/view-all/${params.collectionId}`}
              >
                Alternate View (Display as sheet with headings)
              </Link>
              <InfoPopover
                title="Alternate View"
                content="This works best when the individual sheets are not very large (i.e. less than 50-60 influencers per sheet)."
              />
            </p>
          </div>
        )}
      </div>

      <div className="d-flex flex-column justify-content-around">
        {editTitle ? (
          <form
            onSubmit={(e) => {
              e.preventDefault()
              setEditTitle(false)
            }}
          >
            <input
              id="sheet-title-input"
              className="form-control w-50 mb-3"
              type="text"
              value={collection.title}
              onChange={(e) => {
                dispatch(
                  setTitle({
                    collection_id: params.collectionId,
                    title: e.target.value,
                  }),
                )
              }}
              placeholder="Collection Title (e.g. Roster by Location)"
              data-purpose="collection-title"
            />
            <input type="submit" hidden />
          </form>
        ) : (
          <h2
            data-purpose="collection-title"
            className="selectable"
            onClick={() => setEditTitle(true)}
          >
            {collection.title}{' '}
            <span className="ms-3">
              <FaEdit />
            </span>
          </h2>
        )}

        <TitleModal
          show={showCopySheetModal}
          handleClose={(receivedTitle) => {
            if (_.isEmpty(receivedTitle) || receivedTitle === collection.title) {
              setShowCopySheetModal(false)
              return
            }
            axios
              .post('/api/user/talent-sheets/copy', {
                sheet_id: params.collectionId,
                new_sheet_title: receivedTitle,
              })
              .then((res) => {
                dispatch(fetchSheetsType({ sheet_type: 'talent' }))
                dispatch(fetchBasicInfo())
                navigate(`/talent-sheet/${res.data.sheet_id}`)
              })
            setShowCopySheetModal(false)
          }}
        />

        <DragDropContext onDragEnd={onDragEnd}>
          <>
            {
              <Droppable droppableId={'0'}>
                {(provided, snapshot) => {
                  return (
                    <div className={`row`} ref={provided.innerRef} {...provided.droppableProps}>
                      {draggableCollectionSheets.map((item, itemIndex) => (
                        <Draggable key={item.id} draggableId={item.id} index={itemIndex}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <CollectionTalentSheetItem
                                // talentSheet={item}
                                handleRemove={() => {
                                  handleRemove(item.content.id)
                                }}
                                sheet_id={item.content.id}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )
                }}
              </Droppable>
            }

            <SaveButton />

            <h2 className="text-center">Available Sheets</h2>

            <div className="row">
              <input
                className="w-50 mb-3 form-control"
                placeholder="Filter by Title"
                type="text"
                value={sheetTitleFilter}
                onChange={(e) => setSheetTitleFilter(e.target.value)}
              />
            </div>

            <>
              <div className="mb-3 p-2 shadow">
                <table className="table talent-sheet-table">
                  <thead>
                    <tr>
                      <th scope="col" className="text-center">
                        Name{' '}
                        {sortColumn === 'fullname' && (
                          <Fragment>{sortDirection === 'desc' ? '▼' : '▲'}</Fragment>
                        )}
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(talentSheets, (sheet) => {
                      if (usedSheets.includes(sheet.id)) {
                        return null
                      }
                      if (
                        !sheet.title ||
                        !sheet.title.toLowerCase().includes(sheetTitleFilter.toLowerCase())
                      )
                        return null

                      return (
                        <tr
                          key={`sheet-${sheet.id}`}
                          data-type="available-influencer"
                          data-influencer-id={sheet.id}
                        >
                          <td className="align-middle text-center">
                            <Link to={`/talent-sheet/${sheet.id}`}>{sheet.title}</Link>
                          </td>
                          <td className="align-middle">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              data-purpose="add-sheet-to-collection"
                              onClick={() => {
                                handleAdd(sheet.id)
                              }}
                            >
                              ADD
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </>
          </>
        </DragDropContext>
        <TitleModal
          show={showTitleModal}
          handleClose={(receivedTitle) => {
            setShowTitleModal(false)
            handleSave(receivedTitle)
          }}
        />
      </div>
    </div>
  )
}
