import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import axios from 'axios'

export const fetchSingleProfileForSheet = createAsyncThunk(
  'talentsheet/public/fetchProfile',
  async ({ salesheet_id, influencer_id }) => {
    const params = {
      salesheet_id,
      influencer_id,
    }
    const response = await axios.get(`/api/public/talent-sheet-profile`, {
      params,
    })
    return response.data
  },
)

export const loadPublicSheet = createAsyncThunk(
  '/talentSheet/loadPublicSheet',
  async ({ hash_id, fetch_all = false, page = 1, limit = 15 }) => {
    const params = {
      page: fetch_all ? 1 : page,
      limit: fetch_all ? 1000 : limit,
    }
    let url = `/api/public/talent-sheets/${hash_id}`

    try {
      const { data } = await axios.get(url, { params })
      if (_.isEmpty(data)) {
        return {
          error: 'Something went wrong! No talent sheet was found',
        }
      }
      return data
    } catch (e) {
      return { error: 'Something went wrong! No talent sheet was found' }
    }
  },
)

export const talentSheetSlice = createSlice({
  name: 'talentSheet',
  initialState: {
    sheet_id: null,
    loading: false,
    settings: {},
    sheet: {},
  },
  reducers: {
    setTitle: (state, action) => {
      state.sheet.title = action.payload
    },
  },
})

export const { setTitle } = talentSheetSlice.actions

export default talentSheetSlice.reducer
