import { useState } from 'react'
import {
  EuiBadge,
  EuiPageTemplate,
  EuiButton,
  EuiSpacer,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiSelect,
  EuiHorizontalRule,
  EuiBasicTable,
} from '@elastic/eui'
import { css } from '@emotion/react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { FaTrash } from 'react-icons/fa'

import { getSocialName } from 'utils'
import axios from 'axios'

const handleDelete = (key, socials, setSocials) => {
  const socialToDelete = socials.find((s) => s.key === key)
  const newSocials = socials.filter((s) => s.key !== key)
  if (socialToDelete.primary && newSocials.length > 0) {
    newSocials[0].primary = true
  }
  setSocials(newSocials)
}

const handleChangePrimary = (key, socials, setSocials) => {
  const newSocials = socials.map((s) => {
    if (s.key === key) {
      return { ...s, primary: true }
    } else {
      return { ...s, primary: false }
    }
  })
  setSocials(newSocials)
}

const SocialsTable = ({ socials, setSocials }) => {
  const columns = [
    {
      name: 'Platform',
      align: 'center',
      truncateText: false,
      render: (social) => {
        return (
          <div className="portrait-rounded align-middle">
            <strong>{getSocialName(social.platform)}</strong>
          </div>
        )
      },
    },
    {
      name: 'Handle',
      align: 'center',
      truncateText: false,
      render: (social) => {
        return (
          <div className="portrait-rounded align-middle">
            <strong>{social.handle}</strong>
          </div>
        )
      },
    },
    {
      name: '',
      align: 'center',
      truncateText: false,
      render: (social) => {
        return (
          <div
            onClick={() => handleChangePrimary(social.key, socials, setSocials)}
            className="portrait-rounded align-middle selectable"
          >
            {social.primary ? <EuiBadge>Primary</EuiBadge> : <span className="p-2">-</span>}
          </div>
        )
      },
    },
    {
      name: '',
      align: 'center',
      render: (social) => (
        <div
          onClick={() => handleDelete(social.key, socials, setSocials)}
          style={{ cursor: 'pointer' }}
        >
          <FaTrash />
        </div>
      ),
    },
  ]

  return (
    <>
      <EuiSpacer size="m" />
      <EuiHorizontalRule margin="none" style={{ height: 2 }} />
      <EuiBasicTable
        noItemsMessage={'Add at least one social media account'}
        items={_.map(socials, (s) => s)}
        columns={columns}
        css={css`
          .euiTableHeaderCell {
            background-color: #333;
            color: #fff;
            padding: 0.4rem;
          }
        `}
      />
    </>
  )
}

const NewAddTalent = () => {
  const dispatch = useDispatch()
  const params = useParams()

  const user = useSelector((state) => state.user)
  const userTeamSettings = useSelector((state) => state.user.teamSettings)

  const [newSocial, setNewSocial] = useState({
    platform: 'instagram',
    identifier: '',
  })

  const [talentForm, setTalentForm] = useState({
    name: '',
    socials: [],
  })

  const [socials, setSocials] = useState([])

  const [error, setError] = useState(null)

  const resetEverything = () => {
    setNewSocial({
      platform: 'instagram',
      identifier: '',
    })
    setTalentForm({
      name: '',
      socials: [],
    })
    setSocials([])
    setSubmitComplete(false)
    setError(null)
  }

  const checkSocial = async () => {
    // e.preventDefault()
    // setSocialRequestState('loading')
    // if (url.trim() === '') {
    //   setError('You must include a handle or URL')
    //   return
    // }

    try {
      const params = {
        url: newSocial.identifier,
        platform: newSocial.platform,
      }
      const { data } = await axios.get(`/api/check-social`, { params })

      console.log('data', data)

      if (data.existing) {
        setError(`Your team already has that account: ${data.existing}`)
        return
      }

      const newSocialKey = `${data.social.platform}-${data.social.handle}`

      const existingKeys = socials.map((s) => s.key)

      if (existingKeys.includes(newSocialKey)) {
        setError('You already added that account')
        setTimeout(() => {
          setError(null)
        }, 2000)
        return
      } else {
        setSocials([
          ...socials,
          {
            ...data.social,
            primary: existingKeys.length === 0,
            display: params.platform,
            key: newSocialKey,
          },
        ])
        setNewSocial({
          platform: 'instagram',
          identifier: '',
        })
        setError(null)
      }
    } catch (e) {
      const errorMessage = _.get(e, 'response.data.message')
      setError(
        errorMessage ||
          'Something went wrong trying to process that, please review the information and try again, or contact us if the problem persists.',
      )
    }
  }

  const [submitComplete, setSubmitComplete] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (socials.length === 0) {
        setError('You must include at least one social platform')
        return
      }

      if (talentForm.name.trim() === '') {
        setError('You must specify a name for the talent')
        return
      }

      const formData = {
        name: talentForm.name,
        socials: _.map(socials, (social) => {
          return {
            platform: social.platform,
            handle: social.handle,
            primary: social.primary,
          }
        }),
      }

      const response = await axios.post('/api/add-influencer', { influencer: formData })
      setError(null)
      setSubmitComplete(true)
    } catch (e) {
      console.log(e)
      if (_.get(e, 'response.data.error') === 'no_credits') {
        setError(
          'Sorry, we have to limit the number of accounts you can add automatically. Please contact us about adding more.',
        )
      } else {
        setError(
          'Something unexpected went wrong, sorry. Please contact us about adding this talent',
        )
      }
    }
  }

  if (userTeamSettings?.credits === 0) {
    return (
      <EuiPageTemplate>
        <div
          className="container bg-white d-flex justify-content-center align-items-center flex-column"
          style={{ minHeight: '40vh' }}
        >
          <h2 className="text-center mb-3 fw-bold fs-1">Maximum Roster Reached</h2>
          <p>
            Your team's roster has reached its maximum size. If you wish to add more, please
            contact us to discuss your options.
          </p>
        </div>
      </EuiPageTemplate>
    )
  }

  if (submitComplete) {
    return (
      <EuiPageTemplate>
        <div
          className="container bg-white d-flex justify-content-center align-items-center flex-column"
          style={{ minHeight: '40vh' }}
        >
          <h2 className="text-center mb-3 fw-bold fs-1">Add Talent Request Submitted</h2>
          <div className="fs-5 text-center">
            <p>The information you provided has been received.</p>
            <p>
              The account should appear on your roster in one hour or less. Please be aware that
              some accounts cannot be added automatically. If the account still has not appeared
              after two hours, contact us and we will investigate the issue.
            </p>
          </div>
          <div className="my-3">
            <EuiButton className="" onClick={resetEverything}>
              Add Another
            </EuiButton>
          </div>
        </div>
      </EuiPageTemplate>
    )
  }

  return (
    <>
      <EuiPageTemplate>
        <div className="container-xl bg-white">
          <div className="row mt-4 px-3">
            <div className="col-12 col-md-6">
              <div className="d-flex flex-column">
                <h1 className="fs-1 fw-bold">Add Talent</h1>
                <div className="mt-3">
                  <EuiForm
                    component="form"
                    onSubmit={(e) => {
                      e.preventDefault()
                      console.log('Form submitted')

                      checkSocial()

                      // setTalentForm({
                      //   ...talentForm,
                      //   socials: [...talentForm.socials, newSocial],
                      // })

                      // setNewSocial({
                      //   platform: 'instagram',
                      //   identifier: '',
                      // })
                    }}
                  >
                    <EuiFormRow
                      label="Talent Name"
                      helpText="Enter the name as it should appear on their profile."
                    >
                      <EuiFieldText
                        name="name"
                        value={talentForm.name}
                        onChange={(e) => setTalentForm({ ...talentForm, name: e.target.value })}
                      />
                    </EuiFormRow>

                    <EuiFormRow label="Social Platform">
                      <EuiSelect
                        // hasNoInitialSelection
                        onChange={(e) => {
                          setNewSocial({ ...newSocial, platform: e.target.value })
                        }}
                        value={newSocial.platform}
                        options={[
                          { value: 'instagram', text: 'Instagram' },
                          { value: 'tiktok', text: 'TikTok' },
                          { value: 'youtube', text: 'YouTube' },
                        ]}
                      />
                    </EuiFormRow>

                    <EuiFormRow
                      label="Handle/Link"
                      helpText={
                        newSocial.platform === 'youtube'
                          ? 'For YouTube, provide @username'
                          : 'Provide the profile URL or username'
                      }
                    >
                      <EuiFieldText
                        value={newSocial.identifier}
                        onChange={(e) => {
                          setNewSocial({ ...newSocial, identifier: e.target.value })
                        }}
                        name="social_handle"
                      />
                    </EuiFormRow>

                    <EuiSpacer />

                    <EuiButton disabled={_.isEmpty(newSocial.identifier)} type="submit" fill>
                      Add Social
                    </EuiButton>
                  </EuiForm>{' '}
                </div>
                <div className="mt-2">{error && <span className="text-danger">{error}</span>}</div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <h1 className="fs-1 fw-bold">Talent Info</h1>

              <h1 className="fs-3 mt-3">
                {talentForm.name || (
                  <span className="text-muted">Provide a name (form on left side)</span>
                )}
              </h1>

              <SocialsTable socials={socials} setSocials={setSocials} />
            </div>
          </div>
          <div
            className="row mt-4 p-3"
            style={{
              backgroundColor: '#f4fbff',
            }}
          >
            <div className="col-12 col-md-10">
              <h1 className="fs-1 text-eui-primary-blue fw-bold my-3">Note</h1>
              <p>
                The "primary" account will be used to show the avatar on their profile page, and
                the statistics for that platform will be displayed by default
              </p>
            </div>
            <div className="col-12 col-md-2">
              <div className="d-flex justify-content-end">
                <EuiButton
                  className="mt-3"
                  disabled={socials.length === 0 || _.isEmpty(talentForm.name)}
                  onClick={handleSubmit}
                  fill
                >
                  Submit for Review
                </EuiButton>
              </div>
            </div>
          </div>
        </div>
      </EuiPageTemplate>
    </>
  )
}

export default NewAddTalent
