import React from 'react'

const BottomBar = ({ children }) => {
  return (
    <div
      style={{
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        backgroundColor: 'black',
        color: 'white',
        // textAlign: 'center',
        padding: '10px',
      }}
    >
      {children}
    </div>
  )
}

export default BottomBar
