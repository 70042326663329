import { useMemo } from 'react'
import { EuiTitle } from '@elastic/eui'

import { useSelector } from 'react-redux'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import 'components/InternalProfile.scss'

const GenderStatsBox = ({ gender = 'female', percentage }) => {
  return (
    <div
      className="stats-box mb-2 p-3 d-flex align-items-center"
      style={{
        backgroundColor: gender === 'female' ? '#fff2fc' : '#f3f9ff',
      }}
    >
      <div
        className="stats-indicator"
        style={{
          backgroundColor: gender === 'female' ? '#ee77be' : '#004894',
        }}
      ></div>
      <div className="stats-info">
        <h1 className="fs-5">{gender === 'female' ? 'Female' : 'Male'}</h1>
        <div className="stats-percentage fs-5">{percentage}</div>
      </div>
    </div>
  )
}

const AudienceGenderStats = ({ talent, selectedSocial, setSelectedSocial }) => {
  const { allSocials } = talent.getSocials()

  const socials = useSelector((state) => state.socials.entities)

  const displaySocial = !selectedSocial ? null : socials[selectedSocial]

  if (!displaySocial) {
    return null
  }

  const percentageFemale = displaySocial['audience_female_percent']
  const percentageMale = displaySocial['audience_male_percent']

  if (!percentageFemale && !percentageMale) {
    return null
  }

  return (
    <div className="card shadow-sm p-4">
      <EuiTitle size="xs">
        <h3 className="mb-3">Audience Gender</h3>
      </EuiTitle>

      <div className="row">
        <div className="col-6">
          <GenderStatsBox gender="female" percentage={`${percentageFemale}%`} />
          <GenderStatsBox gender="male" percentage={`${percentageMale}%`} />
        </div>
        <div className="col-6">
          <div className="w-100 p-3">
            <CircularProgressbar
              value={percentageFemale}
              // text={`${percentageFemale}%`}
              styles={buildStyles({
                pathColor: '#f141b5',
                trailColor: '#004894',
              })}
              strokeWidth={20}
              counterClockwise
              // styles={buildStyles({
              //   pathColor: `rgba(255, 0, 131, ${percentageFemale / 100})`,
              //   textColor: '#FF0083',
              //   trailColor: '#d6d6d6',
              //   backgroundColor: '#3e98c7',
              // })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AudienceGenderStats
